<template>
	<div class="driverBlock" v-bind:class="{ 'expanded': (driver.saved === false), 'isself': (driver.isself === true) }">
		<div class="unsavedDriver" v-show="driver.saved !== true">
			<h2 v-show="driver.isself === true">Driving info for {{ driver.firstname }} {{ driver.lastname }}</h2>
			<h2 v-show="driver.isself !== true">Add another driver</h2>
			
			<div class="row formrow nonSelfFields" v-show="driver.isself !== true">
				
				<div class="small-12 medium-6 columns end">
					<div class="fieldwrap" v-bind:class="{ validationErr: ((typeof validationMessages['firstname']) != 'undefined') }">
						<label :for="'db' + idx + '-firstname'">First name</label>
						<input type="text" :id="'db' + idx + '-firstname'" name="firstname" data-model="firstname" v-model="driver.firstname" v-on:blur="capitalizeName">
						<div role="alert" aria-live="polite" tabindex="0"  class="validation" v-html="validationMessages.firstname"></div>
					</div>
				</div>
				
				<div class="small-12 medium-6 columns end">
					<div class="fieldwrap" v-bind:class="{ validationErr: ((typeof validationMessages['lastname']) != 'undefined') }">
						<label :for="'db' + idx + '-lastname'">Last name</label>
						<input type="text" :id="'db' + idx + '-lastname'" name="lastname" data-model="lastname" v-model="driver.lastname" v-on:blur="capitalizeName">
						<div role="alert" aria-live="polite" tabindex="0" class="validation" v-html="validationMessages.lastname"></div>
					</div>
				</div>

				<div class="small-12 medium-6 columns end">
					<div class="fieldwrap" v-bind:class="{ validationErr: ((typeof validationMessages['suffix']) != 'undefined') }">
						<label :for="'db' + idx + '-suffix'">Suffix</label>
						<select :id="'db' + idx + '-suffix'" name="suffix" v-model="driver.suffix">
							<option value="">None</option>
							<option value="Jr">Jr.</option>
							<option value="Sr">Sr.</option>
							<option value="II">II</option>
							<option value="III">III</option>
							<option value="IV">IV</option>
						</select>
						<div role="alert" aria-live="polite" tabindex="0" class="validation" v-html="validationMessages.suffix"></div>
					</div>
				</div>
				
				<div class="small-12 medium-6 columns end">
					<div class="fieldwrap" v-bind:class="{ validationErr: ((typeof validationMessages['relationship']) != 'undefined') }">
						<label :for="'db' + idx + '-relationship'">Relationship to you</label>
						<select :id="'db' + idx + '-relationship'" name="relationship" v-model="driver.relationship">
							<option value="">Select one...</option>
							<option value="Spouse">Spouse</option>
							<option value="Parent">Parent</option>
							<option value="Sibling">Sibling</option>
							<option value="Child">Child</option>
							<option value="Grandchild">Grandchild</option>
							<option value="Domestic Partner">Domestic Partner</option>
							<option value="Other">Other</option>
						</select>
						<div role="alert" aria-live="polite" tabindex="0" class="validation" v-html="validationMessages.relationship"></div>
					</div>
				</div>

				<div class="small-12 medium-6 columns end">
					<div class="fieldwrap" v-bind:class="{ validationErr: ((typeof validationMessages['gender']) != 'undefined') }">
						<label :for="'db' + idx + '-gender'">Gender</label>
						<select :id="'db' + idx + '-gender'" name="gender" v-model="driver.gender">						
							<option v-for="opt in config['drivers_gender'].enum_values" :key="'genderopt-' + opt.value" :value="opt.value" v-html="opt.label"></option>
						</select>
						<div role="alert" aria-live="polite" tabindex="0" class="validation" v-html="validationMessages.gender"></div>
					</div>
				</div>
				
				<div class="small-12 medium-6 columns end">
					<div class="fieldwrap" v-bind:class="{ validationErr: (((typeof validationMessages['dob']) != 'undefined') || ((typeof validationMessages['age']) != 'undefined')) }">
						<label :for="'driver' + idx + '-dob'">Date of birth</label>
						<input type="text" v-bind:id="'driver' + idx + '-dob'" v-model="driver.dob" v-mask="{ mask: '99/99/9999', placeholder: 'mm/dd/yyyy' }">
						<div role="alert" aria-live="polite" tabindex="0" class="validation" v-html="validationMessages.dob"></div>
						<div role="alert" aria-live="polite" tabindex="0" class="validation" v-html="validationMessages.age"></div>
					</div>
				</div>
				
				<div class="small-12 medium-6 columns end">
					<div class="fieldwrap" v-bind:class="{ validationErr: ((typeof validationMessages['marital_status']) != 'undefined') }">
						<label :for="'db' + idx + '-marital_status'">Marital status</label>
						<select :id="'db' + idx + '-marital_status'" name="marital_status" v-model="driver.marital_status" tabindex="0" >
							<option value="">Select one...</option>
							<option value="Single">Single</option>
							<option value="Married">Married</option>
							<option value="Domestic Partner">Domestic Partner</option>
							<option value="Widowed">Widowed</option>
							<option value="Separated">Separated</option>
							<option value="Divorced">Divorced</option>
						</select>
						<div role="alert" aria-live="polite" tabindex="0" class="validation" v-html="validationMessages.marital_status"></div>
					</div>
				</div>
			</div>
			
			<div class="row formrow universalDriverFields">
				
				<div class="small-12 medium-6 columns end">
					<div class="fieldwrap" v-bind:class="{ validationErr: ((typeof validationMessages['license_status']) != 'undefined') }">
						<label :for="'db' + idx + '-license'">Current license status</label>
						<select :id="'db' + idx + '-license'" name="license" v-model="driver.license_status" tabindex="0" >
							<option value="">Select one...</option>
							<option value="Active">Valid U.S. license</option>
							<option value="Expired">Expired</option>
							<option value="International">International</option>
							<option value="Revoked">Revoked</option>
							<option value="Suspended">Suspended</option>
							<option value="Temporary">Temporary</option>
							<option value="Other">Other</option>
						</select>
						<div role="alert" aria-live="polite" tabindex="0" class="validation" v-html="validationMessages.license_status"></div>
					</div>
				</div>
	
				<div class="small-12 medium-6 columns">
					<div class="fieldwrap" v-bind:class="{ validationErr: ((typeof validationMessages['age_licensed']) != 'undefined') }">
						<label :for="'driver' + idx + '-age_licensed'">Age first licensed in the U.S. or Canada</label>
						<input type="text" v-bind:id="'driver' + idx + '-age_licensed'" name="age_licensed" inputmode="numeric" v-model="driver.age_licensed" v-mask="{ regex: '\\d{2}', showMaskOnHover: false, showMaskOnFocus: false, placeholder: '' }">
						<div role="alert" aria-live="polite" tabindex="0" class="validation" v-html="validationMessages.age_licensed"></div>
					</div>
				</div>
				
				<div class="small-12 medium-6 columns">
					<div class="fieldwrap" v-bind:class="{ validationErr: ((typeof validationMessages['license_state']) != 'undefined') }">
						<label :for="'db' + idx + '-license_state'">License state</label>
						<select :id="'db' + idx + '-license_state'" name="license_state" v-model="driver.license_state">
							<option value="AL">Alabama</option>
							<option value="AK">Alaska</option>
							<option value="AZ">Arizona</option>
							<option value="AR">Arkansas</option>
							<option value="CA">California</option>
							<option value="CO">Colorado</option>
							<option value="CT">Connecticut</option>
							<option value="DE">Delaware</option>
							<option value="DC">District Of Columbia</option>
							<option value="FL">Florida</option>
							<option value="GA">Georgia</option>
							<option value="HI">Hawaii</option>
							<option value="ID">Idaho</option>
							<option value="IL">Illinois</option>
							<option value="IN">Indiana</option>
							<option value="IA">Iowa</option>
							<option value="KS">Kansas</option>
							<option value="KY">Kentucky</option>
							<option value="LA">Louisiana</option>
							<option value="ME">Maine</option>
							<option value="MD">Maryland</option>
							<option value="MA">Massachusetts</option>
							<option value="MI">Michigan</option>
							<option value="MN">Minnesota</option>
							<option value="MS">Mississippi</option>
							<option value="MO">Missouri</option>
							<option value="MT">Montana</option>
							<option value="NE">Nebraska</option>
							<option value="NV">Nevada</option>
							<option value="NH">New Hampshire</option>
							<option value="NJ">New Jersey</option>
							<option value="NM">New Mexico</option>
							<option value="NY">New York</option>
							<option value="NC">North Carolina</option>
							<option value="ND">North Dakota</option>
							<option value="OH">Ohio</option>
							<option value="OK">Oklahoma</option>
							<option value="OR">Oregon</option>
							<option value="PA">Pennsylvania</option>
							<option value="RI">Rhode Island</option>
							<option value="SC">South Carolina</option>
							<option value="SD">South Dakota</option>
							<option value="TN">Tennessee</option>
							<option value="TX">Texas</option>
							<option value="UT">Utah</option>
							<option value="VT">Vermont</option>
							<option value="VA">Virginia</option>
							<option value="WA">Washington</option>
							<option value="WV">West Virginia</option>
							<option value="WI">Wisconsin</option>
							<option value="WY">Wyoming</option>
						</select>
						<div role="alert" aria-live="polite" tabindex="0" class="validation" v-html="validationMessages.license_state"></div>
					</div>
				</div>
				
				<div class="small-12 medium-6 columns">
					<div class="fieldwrap" v-bind:class="{ validationErr: ((typeof validationMessages['sr22']) != 'undefined') }">
						<label id="require-an-SR22">Does this driver require an SR22?</label>
						<div class="radioWrap" role="group" aria-labelledby="require-an-SR22">
							<div class="radioOption">
								<label v-bind:for="'driver' + idx + '-sr22-Y'">
									<input type="radio" tabindex="0" v-model="driver.sr22" v-bind:id="'driver' + idx + '-sr22-Y'" :key="'driver' + idx + '-sr22-Y'" value="Yes">
									<span class="selector">Yes</span>
								</label>
							</div>
							<div class="radioOption">
								<label v-bind:for="'driver' + idx + '-sr22-N'">
									<input type="radio" tabindex="0" v-model="driver.sr22" v-bind:id="'driver' + idx + '-sr22-N'" :key="'driver' + idx + '-sr22-N'" value="No">
									<span class="selector">No</span>
								</label>
							</div>
						</div>
						<div role="alert" aria-live="polite" tabindex="0" class="validation" v-html="validationMessages.sr22"></div>
					</div>
				</div>
				
				<div class="small-12 medium-6 columns">
					<div class="fieldwrap" v-bind:class="{ validationErr: ((typeof validationMessages['suspended']) != 'undefined') }">
						<label id="dl-ever-been-suspended">Has this driver's license ever been suspended?</label>
						<div class="radioWrap" role="group" aria-labelledby="dl-ever-been-suspended">
							<div class="radioOption">
								<label v-bind:for="'driver' + idx + '-suspended-Y'">
									<input type="radio" tabindex="0" v-model="driver.suspended" v-bind:id="'driver' + idx + '-suspended-Y'" :key="'driver' + idx + '-suspended-Y'" value="Yes">
									<span class="selector">Yes</span>
								</label>
							</div>
							<div class="radioOption">
								<label v-bind:for="'driver' + idx + '-suspended-N'">
									<input type="radio" tabindex="0" v-model="driver.suspended" v-bind:id="'driver' + idx + '-suspended-N'" :key="'driver' + idx + '-suspended-N'" value="No">
									<span class="selector">No</span>
								</label>
							</div>
						</div>
						<div role="alert" aria-live="polite" tabindex="0" class="validation" v-html="validationMessages.suspended"></div>
					</div>
				</div>

				<div class="small-12 medium-6 columns" v-show="driver.isself === true">
					<div class="fieldwrap" v-bind:class="{ validationErr: ((typeof validationMessages['paperlessBool']) != 'undefined') }">
						<label id="paperless-billing">Would you be interested in receiving a discount for paperless billing?</label>
						<div class="radioWrap" role="group" aria-labelledby="paperless-billing">
							<div class="radioOption">
								<label v-bind:for="'driver' + idx + '-paperlessBool-Y'">
									<input type="radio" tabindex="0" v-model="driver.paperlessBool" v-bind:id="'driver' + idx + '-paperlessBool-Y'" :key="'driver' + idx + '-paperlessBool-Y'" value="Yes">
									<span class="selector">Yes</span>
								</label>
							</div>
							<div class="radioOption">
								<label v-bind:for="'driver' + idx + '-paperlessBool-N'">
									<input type="radio" tabindex="0" v-model="driver.paperlessBool" v-bind:id="'driver' + idx + '-paperlessBool-N'" :key="'driver' + idx + '-paperlessBool-N'" value="No">
									<span class="selector">No</span>
								</label>
							</div>
						</div>
						<div role="alert" aria-live="polite" tabindex="0" class="validation" v-html="validationMessages.paperlessBool"></div>
					</div>
				</div>

				<div class="small-12 medium-6 columns">
					<div class="fieldwrap" v-bind:class="{ validationErr: ((typeof validationMessages['defensiveBool']) != 'undefined') }">
						<label id="Defensive-Driver-course">Has this driver taken a Defensive Driver course?</label>
						<div class="radioWrap" role="group" aria-labelledby="Defensive-Driver-course">
							<div class="radioOption">
								<label v-bind:for="'driver' + idx + '-defensiveBool-Y'">
									<input type="radio" tabindex="0" v-model="driver.defensiveBool" v-bind:id="'driver' + idx + '-defensiveBool-Y'" :key="'driver' + idx + '-defensiveBool-Y'" value="Yes">
									<span class="selector">Yes</span>
								</label>
							</div>
							<div class="radioOption">
								<label v-bind:for="'driver' + idx + '-defensiveBool-N'">
									<input type="radio" tabindex="0" v-model="driver.defensiveBool" v-bind:id="'driver' + idx + '-defensiveBool-N'" :key="'driver' + idx + '-defensiveBool-N'" value="No">
									<span class="selector">No</span>
								</label>
							</div>
						</div>
						<div role="alert" aria-live="polite" tabindex="0" class="validation" v-html="validationMessages.defensiveBool"></div>
					</div>
				</div>

				<div class="small-12 medium-6 columns" v-show="driver.isself === true">
					<div class="fieldwrap" v-bind:class="{ validationErr: ((typeof validationMessages['homeOwnership']) != 'undefined') }">
						<label>Do you own or rent your home?</label>
						<select :id="'db' + idx + '-homeOwnership'" name="home_ownership" v-model="driver.home_ownership">
							<option value="">Select one...</option>
							<option value="Own Home">Own Home</option>
							<option value="Own Condo">Own Condo</option>
							<option value="Own Mobile Home">Own Mobile Home</option>
							<option value="Rent">Rent</option>
							<option value="Other">Other</option>
						</select>
						<div class="validation" v-html="validationMessages.defensiveBool"></div>
					</div>
				</div>
				
				<div class="allIncidentsWrap" v-bind:class="{ validationErr: ((typeof validationMessages['allIncidentsBool']) != 'undefined') }">
					<h3>In the last 5 years, have you had any:</h3>
					
					<div class="allIncidents-question" v-show="allIncidentsBool !== true">
						<p>Accidents? Tickets or violations? Other claims?</p>
						<div class="boolbuttons">
							<button class="boolbutton" v-on:click.prevent="setAllIncidents(true);">Yes</button> 
							<button class="boolbutton" v-on:click.prevent="setAllIncidents(false);" v-bind:class="{ selected: (allIncidentsBool === false) }">No</button>
						</div>
						<div role="alert" aria-live="polite" tabindex="0" class="validation" v-html="validationMessages.allIncidentsBool" style="color: #9F232F !important;"></div>
					</div>
					
					<div class="allIncidents-typesWrap" v-show="allIncidentsBool === true">
						
						<div class="incidentTypeWrap incidentType-accidents" v-bind:class="{ expanded: (accidentsBool === true) }">
							<div class="fieldwrap" v-bind:class="{ validationErr: ((typeof validationMessages['accidentsBool']) != 'undefined') }">
								<div class="row questionrow">
									<div id="accidents" class="small-12 medium-8 columns" v-bind:class="{ 'large-9': (layout == 'standard'), 'large-8': (layout == 'narrow') }">
										In the last 5 years, has this driver had any accidents?
									</div>
									<div class="small-12 medium-4 columns" v-bind:class="{ 'large-3': (layout == 'standard'), 'large-4': (layout == 'narrow') }">
										<div class="radioWrap" role="group" aria-labelledby="accidents">
											<div class="radioOption">
												<label v-bind:for="'driver' + idx + '-accidentsBool-Y'">
													<input type="radio" tabindex="0" class="TLPrivate" v-model="driver.accidentsBool" v-bind:id="'driver' + idx + '-accidentsBool-Y'" :key="'driver' + idx + '-accidentsBool-Y'" value="Yes">
													<span class="selector">Yes</span>
												</label>
											</div>
											<div class="radioOption">
												<label v-bind:for="'driver' + idx + '-accidentsBool-N'">
													<input type="radio" tabindex="0" class="TLPrivate" v-model="driver.accidentsBool" v-bind:id="'driver' + idx + '-accidentsBool-N'" :key="'driver' + idx + '-accidentsBool-N'" value="No">
													<span class="selector">No</span>
												</label>
											</div>
										</div>
									</div>
								</div>
								<div role="alert" aria-live="polite" tabindex="0" class="validation" v-html="validationMessages.accidentsBool"></div>
							</div>
							
							<div class="row accidentDetailsWrap" v-show="accidentsBool === true" v-bind:class="{ validationErr: ((typeof validationMessages['accidents_list']) != 'undefined') }">
								<div class="small-12 columns">
									<ul class="incidentList accidentsList" v-show="hasAccidents === true">
										<li v-for="(accident, accIdx) in driver.accidents" :key="'accident-' + accIdx" class="accident">Accident on {{ accident.accident_date }} <a class="deleteIncidentLink noTrack" href="#" v-on:click.prevent="deleteAccident(accIdx);" aria-label="Delete this accident">delete</a></li>
									</ul>
									<div role="alert" aria-live="polite" tabindex="0" class="validation" v-html="validationMessages.accidents_list"></div>
								</div>
								<div class="accidentForm" v-show="accidentFormShown === true">
									<div class="small-12 columns">
										<p>Include both at fault and not at fault accidents, including vehicle hit while parked and other one-car accidents.</p>
									</div>
									<div class="small-12 medium-6 large-4 xlarge-6 columns">
										<div class="fieldwrap" v-bind:class="{ validationErr: ((typeof validationMessages['accident_date']) != 'undefined') }">
											<label :for="'driver' + idx + '-accident_date'">When did this happen?</label>
											<input type="text" class="TLPrivate" placeholder="mm/yyyy" v-bind:id="'driver' + idx + '-accident_date'" inputmode="numeric" v-model="accident_date" v-mask="{ mask: '99/9999', placeholder: 'mm/yyyy', showMaskOnHover: false, showMaskOnFocus: false }">
											<div role="alert" aria-live="polite" tabindex="0" class="validation" v-html="validationMessages.accident_date"></div>
										</div>
									</div>
									<div class="small-12 medium-6 large-4 xlarge-6 columns">
										<div class="fieldwrap" v-bind:class="{ validationErr: ((typeof validationMessages['atfault']) != 'undefined') }">
											<label id="fault">Were you at fault?</label>
											<div class="radioWrap" role="group" aria-labelledby="fault">
												<div class="radioOption">
													<label v-bind:for="'driver' + idx + 'atfault-Y'">
														<input type="radio" tabindex="0" class="TLPrivate" v-model="atfault" v-bind:id="'driver' + idx + 'atfault-Y'" :key="'driver' + idx + 'atfault-Y'" value="Yes">
														<span class="selector">Yes</span>
													</label>
												</div>
												<div class="radioOption">
													<label v-bind:for="'driver' + idx + 'atfault-N'">
														<input type="radio" tabindex="0" class="TLPrivate" v-model="atfault" v-bind:id="'driver' + idx + 'atfault-N'" :key="'driver' + idx + 'atfault-N'" value="No">
														<span class="selector">No</span>
													</label>
												</div>
											</div>
											<div role="alert" aria-live="polite" tabindex="0" class="validation" v-html="validationMessages.atfault"></div>
										</div>
									</div>
									<div class="small-12 medium-6 large-4 xlarge-6 columns" v-show="atfault == 'No'">
										<div class="fieldwrap" v-bind:class="{ validationErr: ((typeof validationMessages['accident_type']) != 'undefined' ) }">
											<label :for="'db' + idx + '-accident_type'">Description of the accident?</label>
											<select :id="'db' + idx + '-accident_type'" name="accident_type" class="TLPrivate" v-model="accident_type">
												<option value="">Select one...</option>
												<option value="Rear ended">Rear ended</option>
												<option value="Vehicle hit while legally parked">Vehicle hit while legally parked</option>
												<option value="Other driver at fault">Other driver at fault</option>
												<option value="Other Driver Convicted, not insured">Other Driver Convicted, not insured</option>
												<option value="Hit and Run">Hit and Run</option>
												<option value="Your insurance company paid for your injuries">Your insurance company paid for your injuries</option>
											</select>
											<div role="alert" aria-live="polite" tabindex="0" class="validation" v-html="validationMessages.accident_type"></div>
										</div>
									</div>
									<div class="small-12 medium-6 large-4 xlarge-6 columns">
										<div class="fieldwrap" v-bind:class="{ validationErr: ((typeof validationMessages['incident_amount']) != 'undefined' ) }">
											<label :for="'db' + idx + '-incident_amount'">Amount of loss?</label>
											<select :id="'db' + idx + '-incident_amount'" name="incident_amount" class="TLPrivate" v-model="incident_amount">
												<option value="">Select one...</option>
												<option value="0">$0</option>
												<option value="2999">$1 &#8211; $2,999</option>
												<option value="3000">$3,000 or higher</option>
											</select>
											<div role="alert" aria-live="polite" tabindex="0" class="validation" v-html="validationMessages.incident_amount"></div>
										</div>
									</div>
									<div class="small-12 medium-6 large-4 xlarge-6 columns">
										<div class="fieldwrap" v-bind:class="{ validationErr: ((typeof validationMessages['accident_damage_type']) != 'undefined' ) }">
											<label id="injured">Was anyone injured?</label>
											<div class="radioWrap" role="group" aria-labelledby="injured">
												<div class="radioOption">
													<label v-bind:for="'driver' + idx + 'accident_damage_type-Y'">
														<input type="radio" tabindex="0" class="TLPrivate" v-model="accident_damage_type" v-bind:id="'driver' + idx + 'accident_damage_type-Y'" :key="'driver' + idx + 'accident_damage_type-Y'" value="Both">
														<span class="selector">Yes</span>
													</label>
												</div>
												<div class="radioOption">
													<label v-bind:for="'driver' + idx + 'accident_damage_type-N'">
														<input type="radio" tabindex="0" class="TLPrivate" v-model="accident_damage_type" v-bind:id="'driver' + idx + 'accident_damage_type-N'" :key="'driver' + idx + 'accident_damage_type-N'" value="Property">
														<span class="selector">No</span>
													</label>
												</div>
											</div>
											<div role="alert" aria-live="polite" tabindex="0" class="validation" v-html="validationMessages.accident_damage_type"></div>
										</div>
									</div>
									<div class="small-12 medium-12 large-4 xlarge-12 columns">
										<div class="fieldwrap">
											<label><span class="spacer" style="color: #fff;">.</span></label>
											<div class="buttonwrap">
												<input type="button" class="saveElementButton TLPrivate" value="Save Accident" v-on:click.prevent="addAccident()">
											</div>
										</div>
									</div>
								</div>
								<div class="addAnother-wrap" v-show="accidentFormShown !== true">
									<div class="small-12 columns">
										<button class="saveElementButton" v-on:click.prevent="showAccidentForm()"><icon-helper :glyph="'circle-plus'" :variant="'ui'" :classes="'fa-plus-circle'"></icon-helper> Add another accident?</button>
									</div>
								</div> 
							</div>
						</div>
						
						<div class="incidentTypeWrap incidentType-violations" v-bind:class="{ expanded: (violationsBool === true) }">
							<div class="fieldwrap" v-bind:class="{ validationErr: ((typeof validationMessages['violationsBool']) != 'undefined') }">
								<div class="row questionrow">
									<div id="tickets" class="small-12 medium-8 columns" v-bind:class="{ 'large-9': (layout == 'standard'), 'large-8': (layout == 'narrow') }">
										In the last 5 years, has this driver had any tickets or violations?
									</div>
									<div class="small-12 medium-4 columns" v-bind:class="{ 'large-3': (layout == 'standard'), 'large-4': (layout == 'narrow') }">
										<div class="radioWrap" role="group" aria-labelledby="tickets">
											<div class="radioOption">
												<label v-bind:for="'driver' + idx + '-violationsBool-Y'">
													<input type="radio" tabindex="0" class="TLPrivate" v-model="driver.violationsBool" v-bind:id="'driver' + idx + '-violationsBool-Y'" :key="'driver' + idx + '-violationsBool-Y'" value="Yes">
													<span class="selector">Yes</span>
												</label>
											</div>
											<div class="radioOption">
												<label v-bind:for="'driver' + idx + '-violationsBool-N'">
													<input type="radio" tabindex="0" class="TLPrivate" v-model="driver.violationsBool" v-bind:id="'driver' + idx + '-violationsBool-N'" :key="'driver' + idx + '-violationsBool-N'" value="No">
													<span class="selector">No</span>
												</label>
											</div>
										</div>
									</div>
								</div>
								<div role="alert" aria-live="polite" tabindex="0" class="validation" v-html="validationMessages.violationsBool"></div>
							</div>
			
							<div class="row violationDetailsWrap" v-show="violationsBool === true" v-bind:class="{ validationErr: ((typeof validationMessages['violations_list']) != 'undefined') }">
								<div class="small-12 columns">
									<ul class="incidentList violationsList" v-show="hasViolations === true">
										<li v-for="(violation, vIdx) in driver.violations" :key="'violation-' + vIdx" class="violation">Violation on {{ violation.violation_date }} <a class="deleteIncidentLink noTrack" href="#" v-on:click.prevent="deleteViolation(vIdx);" aria-label="Delete this violation">delete</a></li>
									</ul>
									<div role="alert" aria-live="polite" tabindex="0" class="validation" v-html="validationMessages.violations_list"></div>
								</div>
								<div class="violationForm" v-show="violationFormShown === true">
									<div class="small-12 columns">
										<p>Include any traffic violations, both minor and major violations, such as speeding tickets or reckless driving violations. You may exclude parking tickets.</p>
									</div>
									<div class="small-12 medium-6 large-4 xlarge-6 columns">
										<div class="fieldwrap" v-bind:class="{ validationErr: ((typeof validationMessages['violation_date']) != 'undefined') }">
											<label :for="'driver' + idx + '-violation_date'">When did this happen?</label>
											<input type="text" class="TLPrivate" placeholder="mm/yyyy" v-bind:id="'driver' + idx + '-violation_date'" inputmode="numeric" v-model="violation_date" v-mask="{ mask: '99/9999', placeholder: 'mm/yyyy', showMaskOnHover: false, showMaskOnFocus: false }">
											<div role="alert" aria-live="polite" tabindex="0" class="validation" v-html="validationMessages.violation_date"></div>
										</div>
									</div>
									<div class="small-12 medium-6 large-4 xlarge-6 columns">
										<div class="fieldwrap" v-bind:class="{ validationErr: ((typeof validationMessages['dui']) != 'undefined') }">
											<label id="dui">Was this a DUI/DWI?</label>
											<div class="radioWrap" role="group" aria-labelledby="dui">
												<div class="radioOption">
													<label v-bind:for="'driver' + idx + 'dui-Y'">
														<input type="radio" tabindex="0" class="TLPrivate" v-model="dui" v-bind:id="'driver' + idx + 'dui-Y'" :key="'driver' + idx + 'dui-Y'" value="Yes">
														<span class="selector">Yes</span>
													</label>
												</div>
												<div class="radioOption">
													<label v-bind:for="'driver' + idx + 'dui-N'">
														<input type="radio" tabindex="0" class="TLPrivate" v-model="dui" v-bind:id="'driver' + idx + 'dui-N'" :key="'driver' + idx + 'dui-N'" value="No">
														<span class="selector">No</span>
													</label>
												</div>
											</div>
											<div role="alert" aria-live="polite" tabindex="0" class="validation" v-html="validationMessages.dui"></div>
										</div>
									</div>
									<div class="small-12 medium-6 large-4 xlarge-6 columns" v-show="dui != 'Yes'">
										<div class="fieldwrap" v-bind:class="{ validationErr: ((typeof validationMessages['ticket_type']) != 'undefined') }">
											<label :for="'db' + idx + '-ticket_type'">Ticket type</label>
											<select :id="'db' + idx + '-ticket_type'" name="ticket_type" class="TLPrivate" v-model="ticket_type">
												<option value="">Select one...</option>
												<option value="Speeding 1-5 MPH over posted limit">Speeding 1&#8211;5 MPH over posted limit</option>
												<option value="Speeding 6-10 MPH over posted limit">Speeding 6&#8211;10 MPH over posted limit</option>
												<option value="Speeding 11-15 MPH over posted limit">Speeding 11&#8211;15 MPH over posted limit</option>
												<option value="Speeding 16-20 MPH over posted limit">Speeding 16&#8211;20 MPH over posted limit</option>
												<option value="Speeding 21+ MPH over posted limit">Speeding 21+ MPH over posted limit</option>
												<option value="Texting while driving">Texting while driving</option>
												<option value="Failure to obey a traffic signal/sign">Failure to obey a traffic signal/sign</option>
												<option value="Improper passing">Improper passing</option>
												<option value="Improper Turn">Improper Turn</option>
												<option value="Equipment violation">Equipment violation</option>
												<option value="Other minor incident">Other minor incident</option>
												<option value="Any moving violation involving a school bus or school zone">Any moving violation involving a school bus or school zone</option>
												<option value="Reckless Driving/Irresponsible, negligent driving">Reckless Driving/Irresponsible, negligent driving</option>
												<option value="Careless Driving">Careless Driving</option>
												<option value="Conviction or accumulation of points/Suspension or revocation of a license">Conviction or accumulation of points/Suspension or revocation of a license</option>
												<option value="Driving while license is suspended or revoked/ Illegal use of license">Driving while license is suspended or revoked/ Illegal use of license</option>
												<option value="Loaning operators license or registration">Loaning operators license or registration</option>
												<option value="Refusal to submit to a sobriety test">Refusal to submit to a sobriety test</option>
												<option value="Racing violation">Racing violation</option>
												<option value="Failure to stop, identify and report involvement in an accident">Failure to stop, identify and report involvement in an accident</option>
												<option value="Attempting to flee or elude a police officer">Attempting to flee or elude a police officer</option>
												<option value="Homicide/Assault using a motor vehicle/Gross Negligence">Homicide/Assault using a motor vehicle/Gross Negligence</option>
											</select>
											<div role="alert" aria-live="polite" tabindex="0" class="validation" v-html="validationMessages.ticket_type"></div>
										</div>
									</div>
									<div class="small-12 medium-12 large-4 xlarge-12 columns">
										<div class="fieldwrap">
											<label><span class="spacer" style="color: #fff;">.</span></label>
											<div class="buttonwrap">
												<input type="button" class="saveElementButton TLPrivate" value="Save Violation" v-on:click.prevent="addViolation()">
											</div>
										</div>
									</div>
								</div>
								<div class="addAnother-wrap" v-show="violationFormShown !== true">
									<div class="small-12 columns">
										<button class="saveElementButton" v-on:click.prevent="showViolationForm()"><icon-helper :glyph="'circle-plus'" :variant="'ui'" :classes="'fa-plus-circle'"></icon-helper> Add another violation?</button>
									</div>
								</div> 
							</div>
						</div>
		
						<div class="incidentTypeWrap incidentType-claims" v-bind:class="{ expanded: (claimsBool === true) }">
							<div class="fieldwrap" v-bind:class="{ validationErr: ((typeof validationMessages['claimsBool']) != 'undefined') }">
								<div class="row questionrow">
									<div id="claims" class="small-12 medium-8 columns" v-bind:class="{ 'large-9': (layout == 'standard'), 'large-8': (layout == 'narrow') }">
										In the last 5 years, has this driver had any claims?
									</div>
									<div class="small-12 medium-4 columns" v-bind:class="{ 'large-3': (layout == 'standard'), 'large-4': (layout == 'narrow') }">
										<div class="radioWrap" role="group" aria-labelledby="claims">
											<div class="radioOption">
												<label v-bind:for="'driver' + idx + '-claimsBool-Y'">
													<input type="radio" tabindex="0" class="TLPrivate" v-model="driver.claimsBool" v-bind:id="'driver' + idx + '-claimsBool-Y'" :key="'driver' + idx + '-claimsBool-Y'" value="Yes">
													<span class="selector">Yes</span>
												</label>
											</div>
											<div class="radioOption">
												<label v-bind:for="'driver' + idx + '-claimsBool-N'">
													<input type="radio" tabindex="0" class="TLPrivate" v-model="driver.claimsBool" v-bind:id="'driver' + idx + '-claimsBool-N'" :key="'driver' + idx + '-claimsBool-N'" value="No">
													<span class="selector">No</span>
												</label>
											</div>
										</div>
									</div>
								</div>
								<div role="alert" aria-live="polite" tabindex="0" class="validation" v-html="validationMessages.claimsBool"></div>
							</div>
			
							<div class="row claimsDetailsWrap" v-show="claimsBool === true" v-bind:class="{ validationErr: ((typeof validationMessages['claims_list']) != 'undefined') }">
								<div class="small-12 columns">
									<ul class="incidentList claimsList" v-show="hasClaims === true">
										<li v-for="(claim, claimIdx) in driver.claims" :key="'claim-' + claimIdx" class="claim">Claim on {{ claim.claim_date }} <a class="deleteIncidentLink noTrack" href="#" v-on:click.prevent="deleteClaim(claimIdx);">delete</a></li>
									</ul>
									<div role="alert" aria-live="polite" tabindex="0" class="validation" v-html="validationMessages.claims_list"></div>
								</div>
								<div class="claimsForm" v-show="claimsFormShown === true">
									<div class="small-12 columns">
										<p>Include any other auto claims, such as theft, fire, damage from flood or hail, contact with animal or bird and windshield or glass repair.</p>
									</div>
									<div class="small-12 medium-6 large-4 xlarge-6 columns">
										<div class="fieldwrap" v-bind:class="{ validationErr: ((typeof validationMessages['claim_date']) != 'undefined') }">
											<label :for="'driver' + idx + '-claim_date'">When did this happen?</label>
											<input type="text" class="TLPrivate" placeholder="mm/yyyy" v-bind:id="'driver' + idx + '-claim_date'" inputmode="numeric" v-model="claim_date" v-mask="{ mask: '99/9999', placeholder: 'mm/yyyy', showMaskOnHover: false, showMaskOnFocus: false }">
											<div role="alert" aria-live="polite" tabindex="0" class="validation" v-html="validationMessages.claim_date"></div>
										</div>
									</div>
									<div class="small-12 medium-6 large-4 xlarge-6 columns">
										<div class="fieldwrap" v-bind:class="{ validationErr: ((typeof validationMessages['claim_type']) != 'undefined') }">
											<label :for="'db' + idx + '-claim_type'">Claim type?</label>
											<select :id="'db' + idx + '-claim_type'" name="claim_type" class="TLPrivate" v-model="claim_type">
												<option value="">Select one...</option>
												<option value="Animal - Vehicle Damage">Animal - Vehicle Damage</option>
												<option value="Vandalism">Vandalism</option>
												<option value="Glass">Glass</option>
												<option value="Theft">Theft</option>
												<option value="Windstorm">Windstorm</option>
												<option value="Fire">Fire</option>
												<option value="Flood">Flood</option>
												<option value="Towing">Towing</option>
												<option value="Other">Other</option>
											</select>
											<div role="alert" aria-live="polite" tabindex="0" class="validation" v-html="validationMessages.claim_type"></div>
										</div>
									</div>

									<div class="small-12 medium-6 large-4 xlarge-6 columns">
										<div class="fieldwrap" v-bind:class="{ validationErr: ((typeof validationMessages['claim_incident_amount']) != 'undefined' ) }">
											<label :for="'db' + idx + '-claim_incident_amount'">Amount of loss?</label>
											<select :id="'db' + idx + '-claim_incident_amount'" name="claim_incident_amount" class="TLPrivate" v-model="claim_incident_amount">
												<option value="">Unknown</option>
												<option value="500">$1-$500</option>
												<option value="1000">$501-$1,000</option>
												<option value="1500">$1,001-$1,500</option>
												<option value="2500">$1,501-$2,500</option>
												<option value="3000">$2,501-$3,000</option>
												<option value="5000">$3,001-$5,000</option>
												<option value="10000">$5,001-$10,000</option>
												<option value="10001">$10,001+</option>
											</select>
											<div role="alert" aria-live="polite" tabindex="0" class="validation" v-html="validationMessages.claim_incident_amount"></div>
										</div>
									</div>

									<div class="small-12 medium-6 large-4 xlarge-12 end columns">
										<div class="fieldwrap">
											<label><span class="spacer" style="color: #fff;">.</span></label>
											<div class="buttonwrap">
												<input type="button" class="saveElementButton TLPrivate" value="Save Claim" v-on:click.prevent="addClaim()">
											</div>
										</div>
									</div>
								</div>
								<div class="addAnother-wrap" v-show="claimsFormShown !== true">
									<div class="small-12 columns">
										<button class="saveElementButton" v-on:click.prevent="showClaimsForm()"><icon-helper :glyph="'circle-plus'" :variant="'ui'" :classes="'fa-plus-circle'"></icon-helper> Add another claim?</button>
									</div>
								</div> 
							</div>
						</div>
						
					</div>
				</div>
				
			</div>
			
			<div class="buttonwrap">
				<a class="noTrack" href="#" title="Delete driver" v-if="driver.isself !== true" v-show="driver.saved === false" v-on:click.prevent="deleteThis()">Delete</a>
				<input type="button" class="addElementButton" value="Save Driver" v-on:click.prevent="saveDriver()">
			</div>
		</div>
		<div class="savedDriver" v-show="driver.saved === true">
			<div class="row">
				<div class="small-9 medium-10 columns successCol">
					<icon-helper :glyph="'circle-check'" :variant="'ui'" :classes="'fa-check-circle'"></icon-helper> {{ driver.firstname }} {{ driver.lastname }}
				</div>
				<div class="small-3 medium-2 columns gearCol">
					<icon-helper :glyph="'gear'" :variant="'ui'" :classes="'editControl'" v-on:click.native="unsaveThis()"></icon-helper>
					<icon-helper :glyph="'trash'" :variant="'ui'" :classes="'deleteControl'" v-if="driver.isself !== true" v-on:click.native="deleteThis()"></icon-helper>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		driver: {
			type: Object, 
			required: true 
		}, 
		config: {
			type: Object, 
			required: true
		}, 
		layout: {
			type: String, 
			default: "standard"
		}, 
		idx: {
			type: Number, 
			required: true
		}
	}, 
	data: function() {
		return {
			assetRoot: 'cdn/', 	
			validationMessages: {}, 
			accident_date: '', 
			dui: '', 
			atfault: '', 
			accident_type: '', 
			incident_amount: '',
			accident_damage_type: '', 
			ticket_type: '', 
			claim_type: '', 
			claim_incident_amount: '', 
			allIncidentsBool: null, 
			accidentFormShown: true, 
			violationFormShown: true, 
			claimsFormShown: true
		}
	}, 
	computed: {
		accidentsBool: function() {
			return (this.driver.accidentsBool == 'Yes');
		}, 
		hasAccidents: function() {
			return (this.driver.accidents.length > 0);
		}, 
		violationsBool: function() {
			return (this.driver.violationsBool == 'Yes');
		}, 
		hasViolations: function() {
			return (this.driver.violations.length > 0);
		}, 
		claimsBool: function() {
			return (this.driver.claimsBool == 'Yes');
		}, 
		hasClaims: function() {
			return (this.driver.claims.length > 0);
		}, 
		validationFields: function() {
			return Object.keys(this.validationMessages);
		}, 
	}, 
	methods: {
		asset: function(str) {
			return this.assetRoot + str;
		}, 
		setAllIncidents: function(b) {
			if (b === true || b === false) {
				this.allIncidentsBool = b;
				this.removeValidationError('allIncidentsBool');
			}
		}, 
		unsaveThis: function() {
			this.driver.saved = false;
		}, 
		deleteThis: function() {
			let self = this;
			window.vex.dialog.confirm({
				message: "Are you sure you want to delete this driver?", 
				callback: function(b) {
					if (b === true) {
						self.$emit('delete-driver');
					}
				}
			});
		}, 
		removeValidationError: function(fieldname) {
			if (typeof this.validationMessages[fieldname] != 'undefined') { 
				window.Vue.delete(this.validationMessages, fieldname);
			}
		}, 
		clearAllValidationErrors: function() {
			var keys = Object.keys(this.validationMessages);
			if (keys.length > 0) {
				for (let i=0; i<keys.length; i++) {
					this.removeValidationError(keys[i]);
				}
			}
		}, 
		clearSubFormErrors: function() {
			const keys = ['accident_date', 'atfault', 'violation_date', 'dui', 'claim_date'];
			if (keys.length > 0) {
				for (let i=0; i<keys.length; i++) {
					this.removeValidationError(keys[i]);
				}
			}
		}, 
		isreq: function(fieldname) {
			// Check whether a field is required.
			return (window.is.inArray(fieldname, window.RateApp.requiredFields));
		}, 
		saveDriver: function() {
			// Validate this driver. 
			this.clearSubFormErrors();
			if (this.driver.isself !== true) {
				// Validate fields for additional (non-self) drivers.
				if (this.isreq('drivers.relationship_to_primary') && window.isBlank(this.driver.relationship)) {
					this.$set(this.validationMessages, 'relationship', "Please select this driver's relationship to you.");
				} else {
					this.removeValidationError('relationship');
				}
				if (this.isreq('drivers.first_name') && window.isBlank(this.driver.firstname)) { 
					this.$set(this.validationMessages, 'firstname', "First name cannot be blank.");
				} else {
					this.removeValidationError('firstname');
				}
				if (this.isreq('drivers.last_name') && window.isBlank(this.driver.lastname)) { 
					this.$set(this.validationMessages, 'lastname', "Last name cannot be blank.");
				} else {
					this.removeValidationError('lastname');
				}
				if (this.isreq('drivers.suffix') && window.isBlank(this.driver.suffix)) {
					this.$set(this.validationMessages, 'suffix', 'Please select a suffix.');
				} else {
					this.removeValidationError('suffix');
				}
				if (this.isreq('drivers_gender') && window.isBlank(this.driver.gender)) {
					this.$set(this.validationMessages, 'gender', "Please select this driver's gender.");
				} else {
					this.removeValidationError('gender');
				}
				if (this.isreq('drivers.date_of_birth') && (window.isBlank(this.driver.dob) === true || window.jQuery('#driver' + this.idx + '-dob')[0].inputmask.isComplete() === false || window.moment(this.driver.dob, 'MM/DD/YYYY', true).isValid() === false)) {
					this.$set(this.validationMessages, 'dob', "Please enter this driver's date of birth.");
				} else if (window.isBlank(this.driver.dob) === false && (window.moment().diff(window.moment(this.driver.dob, 'MM/DD/YYYY', true), 'years') > 99)) {
					this.$set(this.validationMessages, 'dob', "Driver age must be under 100. Please check this driver's date of birth.");
				} else {
					this.removeValidationError('dob');
				}
				if (this.isreq('drivers.marital_status') && window.isBlank(this.driver.marital_status)) { 
					this.$set(this.validationMessages, 'marital_status', "Please select this driver's marital status.");
				} else {
					this.removeValidationError('marital_status');
				}
			}
			if (this.isreq('drivers.license_status') && window.isBlank(this.driver.license_status)) {
				this.$set(this.validationMessages, 'license_status', "Please select this driver's license status.");
			} else {
				this.removeValidationError('license_status');
			}
			if ((this.isreq('drivers.license_age') && window.isBlank(this.driver.age_licensed) === true) || (window.isBlank(this.driver.age_licensed) === false && window.jQuery('#driver' + this.idx + '-age_licensed')[0].inputmask.isComplete() === false)) {
				this.$set(this.validationMessages, 'age_licensed', "Please enter the age at which this driver was first licensed.");
			} else {
				this.removeValidationError('age_licensed');
			}
			if (this.isreq('drivers.license_state') && window.isBlank(this.driver.license_state) === true) {
				this.$set(this.validationMessages, 'license_state', 'Please select a state.');
			} else {
				this.removeValidationError('license_state');
			}
			if (this.isreq('drivers.sr22') && ['Yes', 'No'].indexOf(this.driver.sr22) == -1) {
				this.$set(this.validationMessages, 'sr22', 'Please select Yes or No.');
			} else {
				this.removeValidationError('sr22');
			}
			if (this.isreq('drivers.license_suspensions') && ['Yes', 'No'].indexOf(this.driver.suspended) == -1) {
				this.$set(this.validationMessages, 'suspended', 'Please select Yes or No.');
			} else {
				this.removeValidationError('suspended');
			}
			if (this.isreq('drivers.paperless_discount') && ['Yes', 'No'].indexOf(this.driver.paperlessBool) == -1) {
				this.$set(this.validationMessages, 'paperlessBool', 'Please select Yes or No.');
			} else {
				this.removeValidationError('paperlessBool');
			}
			if (this.isreq('drivers.defensive_driver_discount') && ['Yes', 'No'].indexOf(this.driver.defensiveBool) == -1) {
				this.$set(this.validationMessages, 'defensiveBool', 'Please select Yes or No.');
			} else {
				this.removeValidationError('defensiveBool');
			}
			if (this.isreq('drivers[0].home_ownership') && this.driver.isself === true && window.isBlank(this.driver.home_ownership)) {
				this.$set(this.validationMessages, 'homeOwnership', "Please indicate whether you own or rent your home.");
			} else {
				this.removeValidationError('homeOwnership');
			}
			if (this.accidentsBool === true && this.hasAccidents === false) {
				this.$set(this.validationMessages, 'accidents_list', "Please add at least one accident.");
			} else {
				this.removeValidationError('accidents_list');
			}
			if (this.violationsBool === true && this.hasViolations === false) {
				this.$set(this.validationMessages, 'violations_list', "Please add at least one ticket or violation.");
			} else {
				this.removeValidationError('violations_list');
			}
			if (this.claimsBool === true && this.hasClaims === false) {
				this.$set(this.validationMessages, 'claims_list', "Please add at least one claim.");
			} else {
				this.removeValidationError('claims_list');
			}
			if (this.allIncidentsBool !== true && this.allIncidentsBool !== false) {
				this.$set(this.validationMessages, 'allIncidentsBool', "Please indicate whether you have had any incidents.");
			} else {
				this.removeValidationError('allIncidentsBool');
			}
			
			// Update the "saved" boolean for this driver.
			if (this.validationFields.length > 0) {
				window.vex.dialog.alert("Please correct the highlighted validation errors before saving this driver.");
				return false;
			}
			this.driver.saved = true;
			window.Rates.scrollUp();
		}, 
		addAccident: function() {
			// Validate input.
			this.clearAllValidationErrors();
			const fiveYearsAgo = window.moment(("01/01/" + (window.moment().year() - 5)), "MM/DD/YYYY", true);
			let date_entered = window.moment(this.accident_date, 'MM/YYYY', true);
			if (this.isreq('drivers.incidents.incident_month_year') && (window.isBlank(this.accident_date) === true || window.jQuery('#driver' + this.idx + '-accident_date')[0].inputmask.isComplete() === false || date_entered.isValid() === false)) {
				this.$set(this.validationMessages, 'accident_date', "Please enter the month and year of this accident.");
			} else if (window.isBlank(this.accident_date) === false && date_entered.isValid() === true && (date_entered.isAfter(fiveYearsAgo) === false || date_entered.isAfter(window.moment()) === true)) {
				this.$set(this.validationMessages, 'accident_date', "Please enter a date (month and year) within the last 5 years.");
			} else {
				this.removeValidationError('accident_date');
			}
			if (this.isreq('drivers.incidents.accident_at_fault') && ['Yes', 'No'].indexOf(this.atfault) == -1) {
				this.$set(this.validationMessages, 'atfault', "Please indicate whether you were at fault.");
			} else {
				this.removeValidationError('atfault');
			}
			if (this.atfault == 'No' && this.isreq('drivers.incidents.incident_type') && window.isBlank(this.accident_type) === true) {
				this.$set(this.validationMessages, 'accident_type', 'Please select the accident description.');
			} else {
				this.removeValidationError('accident_type');
			}
			if (this.isreq('drivers.incidents.incident_amount') && window.isBlank(this.incident_amount)) {
				this.$set(this.validationMessages, 'incident_amount', 'Please speficy the amount of the loss.');
			} else {
				this.removeValidationError('incident_amount');
			}
			if (this.isreq('drivers.incidents.incident_damage_type') === true && ['Property', 'Both'].indexOf(this.accident_damage_type) == -1) {
				this.$set(this.validationMessages, 'accident_damage_type', 'Please indicate whether anyone was injured.');
			} else {
				this.removeValidationError('accident_damage_type');
			}
			if (this.validationFields.length > 0) {
				window.vex.dialog.alert("Please correct the highlighted validation errors.");
				return false;
			}
			
			// Save input.
			let accidentTemplate = {
				'accident_date': this.accident_date, 
				'atfault': this.atfault, 
				'accident_type': this.accident_type, 
				'incident_amount': this.incident_amount, 
				'accident_damage_type': this.accident_damage_type
			};
			this.driver.accidents.push(accidentTemplate);
			// Reset the form.
			this.accident_date = '';
			this.atfault = '';
			this.accident_type = '';
			this.incident_amount = '';
			this.accident_damage_type = '';
			this.accidentFormShown = false;
		}, 
		deleteAccident: function(idx) {
			this.clearAllValidationErrors();
			this.driver.accidents.splice(idx, 1);
			if (this.driver.accidents.length == 0) { this.accidentFormShown = true; }
		}, 
		showAccidentForm: function() {
			this.accidentFormShown = true;
		}, 
		addViolation: function() {
			// Validate input.
			this.clearAllValidationErrors();
			const fiveYearsAgo = window.moment(("01/01/" + (window.moment().year() - 5)), "MM/DD/YYYY", true);
			let date_entered = window.moment(this.violation_date, 'MM/YYYY', true);
			if (this.isreq('drivers.incidents.incident_month_year') && (window.isBlank(this.violation_date) === true || window.jQuery('#driver' + this.idx + '-violation_date')[0].inputmask.isComplete() === false || window.moment(this.violation_date, 'MM/YYYY', true).isValid() === false)) {
				this.$set(this.validationMessages, 'violation_date', "Please enter the month and year of this violation.");
			} else if (window.isBlank(this.violationDate) === false && date_entered.isValid() && (date_entered.isAfter(fiveYearsAgo) === false || date_entered.isAfter(window.moment()) === true)) {
				this.$set(this.validationMessages, 'violation_date', "Please enter a date (month and year) within the last 5 years.");
			} else {
				this.removeValidationError('violation_date');
			}
			if (this.isreq('drivers.incidents.incident_type') === true && ['Yes', 'No'].indexOf(this.dui) == -1) {
				this.$set(this.validationMessages, 'dui', "Please indicate whether this was a DUI/DWI.");
			} else {
				this.removeValidationError('dui');
			}
			if (this.dui != 'Yes' && this.isreq('drivers.incidents.incident_type') && window.isBlank(this.ticket_type)) {
				this.$set(this.validationMessages, 'ticket_type', 'Please select the ticket type.');
			} else {
				this.removeValidationError('ticket_type');
			}
			if (this.validationFields.length > 0) {
				window.vex.dialog.alert("Please correct the highlighted validation errors.");
				return false;
			}
			
			// Save input.
			let violationTemplate = {
				'violation_date': this.violation_date, 
				'dui_dwi': this.dui, 
				'ticket_type': this.ticket_type
			};
			this.driver.violations.push(violationTemplate);
			// Reset the form.
			this.violation_date = '';
			this.dui = '';
			this.ticket_type = '';
			this.violationFormShown = false;
		}, 	
		deleteViolation: function(idx) {
			this.clearAllValidationErrors();
			this.driver.violations.splice(idx, 1);
			if (this.driver.violations.length == 0) { this.violationFormShown = true; }
		}, 
		showViolationForm: function() {
			this.violationFormShown = true;
		}, 
		addClaim: function() {
			// Validate input.
			this.clearAllValidationErrors();
			const fiveYearsAgo = window.moment(("01/01/" + (window.moment().year() - 5)), "MM/DD/YYYY", true);
			let date_entered = window.moment(this.claim_date, 'MM/YYYY', true);
			if (this.isreq('drivers.incidents.incident_month_year') && (window.isBlank(this.claim_date) === true || window.jQuery('#driver' + this.idx + '-claim_date')[0].inputmask.isComplete() === false || window.moment(this.claim_date, 'MM/YYYY', true).isValid() === false)) {
				this.$set(this.validationMessages, 'claim_date', "Please enter the month and year of this claim.");
			} else if (window.isBlank(this.claim_date) === false && date_entered.isValid() && (date_entered.isAfter(fiveYearsAgo) === false || date_entered.isAfter(window.moment()) === true)) {
				this.$set(this.validationMessages, 'claim_date', "Please enter a date (month and year) within the last 5 years.");
			} else {
				this.removeValidationError('claim_date');
			}
			if (this.isreq('drivers.incidents.incident_type') && window.isBlank(this.claim_type)) {
				this.$set(this.validationMessages, 'claim_type', 'Please select the type of claim.');
			} else {
				this.removeValidationError('claim_type');
			}
			if (this.isreq('drivers.incidents.incident_amount') && window.isBlank(this.claim_incident_amount)) {
				this.$set(this.validationMessages, 'claim_incident_amount', 'Please specify the amount of the loss.');
			} else {
				this.removeValidationError('claim_incident_amount');
			}
			if (this.validationFields.length > 0) {
				window.vex.dialog.alert("Please correct the highlighted validation errors.");
				return false;
			}
			
			// Save input.
			let claimTemplate = {
				'claim_date': this.claim_date, 
				'claim_type': this.claim_type, 
				'incident_amount': this.claim_incident_amount
			};
			this.driver.claims.push(claimTemplate);
			// Reset the form.
			this.claim_date = '';
			this.claim_type = '';
			this.claim_incident_amount = '';
			this.claimsFormShown = false;
		}, 
		deleteClaim: function(idx) {
			this.clearAllValidationErrors();
			this.driver.claims.splice(idx, 1);
			if (this.driver.claims.length == 0) { this.claimsFormShown = true; }
		}, 
		showClaimsForm: function() {
			this.claimsFormShown = true;
		}, 
		capitalizeName: function(evt) {
			if (evt.target.value.length > 0 && evt.target.getAttribute('data-model') != null) {
				let val = evt.target.value;
				let first = val.substring(0,1);
				if (first != first.toUpperCase()) {
					// Replace first character with uppercase.
					val = first.toUpperCase() + val.substring(1);
					this.$set(this.driver, evt.target.getAttribute('data-model'), val);
				}	
			}
			return true;
		}
	}
}
</script>